<template>
  <div>
    <b-modal
      id="create-variants-order"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="title"
      hide-footer
    >
      {{ bodyText }}?

      <div
        v-for="name in selectedRows"
        :key="name"
        style="font-weight: bold;"
      >
        - {{ name.materialVariantName }}
      </div>
      <b-form-group
        :label="$t('Supplier')"
        style="width: 100%;"
      >
        <vue-select
          v-model="supplierId"
          :options="getSupplierDropDown"
          label="supplierName"
          :reduce="(e) => e.supplierId"
          :placeholder="$t('Main')"
          :clearable="false"
        />
      </b-form-group>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit()"
        >
          {{ $t('Confirm') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'bodyText', 'selectedRows'],
  data() {
    return {
      modalTriggeredByToggle: false,
      supplierId: null,
    }
  },
  computed: {
    ...mapGetters(['getSupplierDropDown']),
  },
  mounted() {
    this.suppliersDropDown({
      isActive: true,
    })
  },
  methods: {
    ...mapActions(['loadSaturdayWork', 'suppliersDropDown']),
    onSubmit() {
      const orderIds = this.selectedRows.map((item) => { return item.id });
      this.$emit('orderArray', { orderIds, supplierId: this.supplierId });
      this.$refs.modal.hide()
    },
    onCancel() {
      this.$emit('saturdayy', this.saturday)

      this.modalTriggeredByToggle = false;
      this.$refs.modal.hide();
    },
  },
}
</script>

  <style lang="scss" scoped>
    form {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .deactivate-modal__select {
      display: flex;
      flex-direction: column;
      select {
        margin-bottom: 10px;
        padding: 10px;
      }
      button {
        align-self: end;
      }
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .my-class {
    background: black;
    font-weight: bold;
  }

  </style>
