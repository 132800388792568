<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div
    style="margin-top: 20px; width: 40%;"
  >
    <div style="display: flex; flex-direction: row; gap: 30px;">
      <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between; margin-top: 5px; margin-right: 10px;">
        <b-form-group
          :label="$t('SelectState')"
          style="margin-bottom: 20px;"
        >
          <vue-select
            v-model="selectedOrderState"
            class="custom-select2"
            :placeholder="$t('SelectState')"
            :options="states"
            :clearable="false"
            :reduce="(e) => e.value"
            label="text"
          />
        </b-form-group>
      </div>
      <div style="display: flex; margin-bottom: 5px; width: 65%;">
        <b-form-group
          :label="$t('EditY')"
        >
          <div
            class="year"
            style="width: 100% !important;"
          >
            <button
              class="arrowButton"
              @click="previousYear"
            >
              <b-icon-arrow-left />
            </button>
            <date-picker
              v-model="selectedYear"
              value-type="format"
              :clearable="false"
              class="form-control"
              format="YYYY"
              type="year"
              :placeholder="$t('EditY')"
            />
            <button
              class="arrowButton"
              style="margin-left: 5px"
              @click="nextYear"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('SelectMonth')"
        >
          <div class="year">
            <date-picker
              v-model="selectedMonth"
              value-type="format"
              class="form-control"
              format="MMMM"
              type="month"
              :placeholder="$t('Month')"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <div
      class="tt"
      style=" padding-top: 16px;"
    >
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('Supplier') }}
            </th>
            <th>
              {{ $t('State') }}
            </th>
            <th>
              {{ $t('Files') }}
            </th>
            <th>
              {{ $t('TrackingHistory') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for=" ( orders ,index) in getOrderedMaterials"
            :key="index"
            class="hover-row"
            :style="classNameCh === orders.id ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="sendProps(orders)"
          >
            <td>
              {{ orders.orderNumber }}
            </td>
            <td>
              {{ orders.mainSupplier }}
            </td>

            <td
              v-if="orders.state == 'Ordered'"
            >
              <b-button
                v-b-modal.change-state-material-order
                style="font-size: small; width: 100%; text-align: left;"
                class="buttonOrdered"
                @click.stop="stateMethod(orders)"
              >
                ⦿ {{ $t(orders.state) }}
              </b-button>
            </td>
            <td
              v-if="orders.state === 'Received'"
            >
              <div style="background-color: rgb(172, 255, 172); padding: 6px; border-radius: 7px; color: rgb(2, 102, 2); cursor: default;">
                ⦿ {{ $t(orders.state) }}
              </div>
            </td>
            <td
              v-if="orders.state === 'Cancelled'"
            >
              <div style="background-color: rgb(255, 194, 194); padding: 6px; border-radius: 7px; color: red; cursor: default;">
                ⦿ {{ $t(orders.state) }}
              </div>
            </td>
            <td>
              <div
                style="display: flex; flex-direction: row !important;"
              >
                <div
                  v-for="(img, index) in orders.photos"
                  :key="img"
                  :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
                  style="background-color: gainsboro; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center; cursor: default;"
                >
                  <img
                    :class="{'photo': true, 'hovered': hoveredIndex === index,'second-photo': index === 1, 'third-photo': index === 2,}"
                    :style="{ transform: `scale(${img.zoom})` }"
                    style="cursor: pointer;"
                    :src="img"
                    @click.stop="callImagesMethod(orders)"
                    @mouseover="showText()"
                    @mouseleave="zoomOut"
                  >
                  <b-icon-x-circle
                    :key="img"
                    style="margin-left: 25px; margin-right: 5px; cursor: pointer;"
                    @click="handleDeleteClick(orders)"
                  />
                </div>
              </div>
              <!-- <img
                v-else
                v-b-tooltip="$t('Click To View Photo')"
                style="height: 40px; border-radius: 3px; "
                :src="orders.photos[0]"
                alt=""
                @click.stop="callImagesMethod(orders)"
              > -->
            </td>
            <td
              v-b-toggle.sidebar-right
              style="text-align: center; cursor: pointer"
              @click.stop="goToOrderHistory(orders.id)"
            >
              <p>
                <b-icon-arrow-right
                  class="bigger-icon"
                  color="black"
                />
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <b-pagination
          v-if="getTotalItemsForMaterialOrders > 15"
          v-model="page"
          :total-rows="getTotalItemsForMaterialOrders"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
      <ChangeMaterialOrderState
        :order-cart-id="orderCartId"
        @changeState="changeState"
        @totalPrice="totalPrice"
      />
      <div
        v-if="isSidebarOpen"
        class="backdrop"
      />
      <HistoryVariantOrder />
      <AddOrderMaterialFile
        :order-cart-id="classNameCh"
        :selected-order-state="selectedOrderState"
        :page="page"
        :page-size="pageSize"
        :selected-month="selectedMonth"
        :selected-year="selectedYear"
      />
      <vue-easy-lightbox
        :esc-disabled="false"
        move-disabled
        :visible="image.visible"
        :imgs="image.imgs"
        :index="image.index"
        @hide="handleHide"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChangeMaterialOrderState from '@/components/cart/modals/ChangeMaterialOrderState.vue';
import AddOrderMaterialFile from '@/components/cart/modals/AddOrderMaterialFile.vue'
import VueEasyLightbox from 'vue-easy-lightbox';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import HistoryVariantOrder from '@/components/cart/HistoryVariantOrder.vue';

export default {
  components: {
    DatePicker,
    ChangeMaterialOrderState,
    AddOrderMaterialFile,
    HistoryVariantOrder,
    VueEasyLightbox,
  },
  data() {
    return {
      orderCartId: null,
      selectedYear: new Date().getFullYear().toString(),
      selectedMonth: null,
      isTextShown: false,
      hoveredIndex: null,
      materialVariantId: null,
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0,
      },
      selectedOrderState: 'Ordered',
      classNameCh: '',
      page: 1,
      order: null,
      pageSize: 15,
      states: [
        { value: 'Ordered', text: this.$t('Ordered') },
        { value: 'Cancelled', text: this.$t('Cancelled') },
        { value: 'Received', text: this.$t('Received') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getLanguage', 'getImagesVariantOrder', 'getOrderedMaterials', 'getTotalItemsForMaterialOrders']),
  },
  watch: {
    page(value) {
      this.loadOrderedMaterials({
        state: this.selectedOrderState,
        month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
        year: this.selectedYear,
        pageNumber: value,
        pageSize: this.pageSize,
      })
    },
    selectedYear(newDate) {
      this.loadOrderedMaterials({
        state: this.selectedOrderState,
        month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
        year: newDate,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    selectedMonth(newDate) {
      if (newDate == null) {
        this.loadOrderedMaterials({
          state: this.selectedOrderState,
          year: this.selectedYear,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
      } else {
        newDate = new Date(`${newDate} 1`).toLocaleDateString(undefined, { month: '2-digit' });
        this.loadOrderedMaterials({
          state: this.selectedOrderState,
          month: newDate || null,
          year: this.selectedYear,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
      }
    },
    selectedOrderState(value) {
      if (value == null) {
        this.loadOrderedMaterials({
          state: null,
          month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
          year: this.selectedYear,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
      }
      this.loadOrderedMaterials({
        state: value,
        month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null,
        year: this.selectedYear,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      this.$emit('changeState', value)
    },
  },
  // created() {
  //   this.loadOrderedMaterials({
  //     state: null, month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null, year: this.selectedYear, pageNumber: 1, pageSize: this.pageSize,
  //   })
  // },
  methods: {
    ...mapActions(['clearLoadOrderItems', 'receiveMaterialVariants', 'loadOrderedMaterials', 'loadOrderItems', 'loadTrackingMaterialOrder', 'changeStateOfMaterialOrder', 'getImagesOrderItemId', 'deleteFileFromMaterialOrder', 'loadOrderItemsByCardId']),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
    previousYear() {
      this.selectedYear = moment(this.selectedYear, 'YYYY').subtract(1, 'year').format('YYYY');
    },
    nextYear() {
      this.selectedYear = moment(this.selectedYear, 'YYYY').add(1, 'year').format('YYYY');
    },
    openAddFileModal(value) {
      this.classNameCh = value.id
      this.$bvModal.show('add-order-material-file');
    },
    changeState(value) {
      this.changeStateOfMaterialOrder({
        object: value,
        successCallback: () => {
          this.loadOrderedMaterials({
            state: this.selectedOrderState, month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null, year: this.selectedYear, pageNumber: this.page, pageSize: this.pageSize,
          })
        },
      })
    },
    async totalPrice(obj) {
      await this.receiveMaterialVariants({
        object: obj,
        successCallback: () => {
          this.loadOrderedMaterials({
            state: this.selectedOrderState, month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null, year: this.selectedYear, pageNumber: this.page, pageSize: this.pageSize,
          })
          this.clearLoadOrderItems()
        },
      })
    },
    stateMethod(value) {
      this.orderCartId = value.id
      this.loadOrderItemsByCardId({
        orderCartId: value.id,
      })
    },
    sendProps(value) {
      this.classNameCh = value.id
      this.loadOrderItems({ orderCartId: value.id })
    },
    goToOrderHistory(item) {
      this.loadTrackingMaterialOrder({ orderCartId: item });
      this.classNameCh = item;
    },
    async callImagesMethod(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        await this.getImagesOrderItemId(item.id).then((res) => {
          console.log(res);
        })
      } catch (e) {
        console.log(e);
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesVariantOrder.length; i++) {
        const image = this.getImagesVariantOrder[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = 0
    },
    async handleDeleteClick(item) {
      try {
        await this.getImagesOrderItemId(item.id).then((res) => {
          console.log(res);
        })
      } catch (e) {
        console.log(e);
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesVariantOrder.length; i++) {
        const image = this.getImagesVariantOrder[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
        })
      }
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'default',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deleteFileFromMaterialOrder({
              id: this.image.imgs[0].fileDataId,
              successCallback: () => {
                this.loadOrderedMaterials({
                  state: this.selectedOrderState, month: this.selectedMonth ? new Date(`${this.selectedMonth} 1`).toLocaleDateString(undefined, { month: '2-digit' }) : null, year: this.selectedYear, pageNumber: this.page, pageSize: this.pageSize,
                })
              },
            });
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
  },
};
</script>

  <style scoped lang="scss">

  .team_table td {
    padding: 11px 9px !important;
  }

  .buttonOrdered {
    border: none;
  }

  .buttonOrdered:hover {
    background: white;
    color: #FF274F;
  }


  .team_table thead {
    border-bottom: 1px solid #dcdfe6;
    color: #606266;
    text-align: left;
  }

  .team_table tr {
    border-bottom: 1px solid #dcdfe6;
    color: #606266;
    cursor: pointer;
  }
  .hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  width: 600px !important;

  .tt {
    .vgt-table {
      th.sortable button {
        display: none !important;
      }
    }
  }

  .mats-listing {
    margin-top: 0;
  }
}

.button {
  margin-bottom: 15px !important;
  width: 195px;
}

// .team_table .tr{
//   border-radius: 10px 10px 10px 10px !important;
// }
tr:last-child {
  border-bottom: none !important;
}

@media screen and (max-width: 1723px) {
  .mats-listing__wrapper {
    width: 400px !important;

    div {
      button {
        width: auto;
        height: 36px;
      }

      input {
        width: 160px;
      }

    }

    .table__search-input {
      padding-left: 0px;
      margin-left: 0px !important;
    }
  }

}

@media screen and (max-width: 1400px) {
  .mats-listing__wrapper {
    width: 100% !important;
  }
}


.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.bigger-icon {
  font-size: 24px;
  align-self: center;
}
.photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }
.year {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.arrowButton {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
}
  .hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
}
.second-photo{
  margin-left: 10px;
}
.third-photo{
  margin-left: 10px;
}

.is-active {
  background-color: #dedede;
}
  </style>
