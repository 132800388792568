<template>
  <div>
    <b-modal
      id="change-state-material-order"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeStateOrder')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="vacation">
        <b-form-group
          id="input-select-1"
          :label="`${$t('ChangeOrderState')}:`"
          label-for="select-1"
        >
          <vue-select
            v-model="form.state"
            :options="states"
            label="text"
            :reduce="(e) => e.value"
            :placeholder="$t('SelectState') "
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <div v-if="form.state === 'Received'">
          <table
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('Material') }}
                </th>
                <th>
                  {{ $t('Amount') }}
                </th>
                <th>
                  {{ $t('Price') }}({{ $t('Meter') }}/{{ $t('Part') }})
                </th>
                <th>
                  {{ $t('Total') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(material,index) in getOrderItemsCartId"
                :key="index"
              >
                <td>
                  {{ material.materialVariantName }}
                </td>
                <td style="text-align: center">
                  {{ material.orderedAmount }}
                </td>
                <td style="text-align: center">
                  {{ numbersWithcomma(calculateTotal(receivedMaterialVariants[index],material.orderedAmount)) }}
                </td>
                <td>
                  <b-form-input
                    v-model="receivedMaterialVariants[index]"
                    :placeholder="$t('Price')"
                    aria-describedby="input-2-live-feedback"
                  />
                </td>
              </tr>
              <tr>
                <td
                  colspan="4"
                  style="text-align: right; padding: 8px"
                >
                  {{ $t('TotalPrice') }}: {{ numbersWithcomma(calculatingTotalPrice) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-form-group
          id="input-group-8"
          :label="$t('Notes')"
          label-for="input-25"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.notes"
            :placeholder="$t('Describe')"
            rows="3"
            max-rows="10"
          />
        </b-form-group>
        <AddFile
          v-if="form.state === 'Received'"
          ref="addFile"
          :folder="'OrderCartPhoto'"
          :label-text="'UploadInvoice'"
          :file-name="uploadPhotos"
        />
      </b-form>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          :disabled="loading || form.state == null"
          class="buttonSubmit"
          @click="onSubmit"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm"
          />
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;
                  background: white;
                  color: black;
                  border-color: white;
                  font-weight: 400;"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddFile from '@/modals/AddFile.vue';
import { validationMixin } from 'vuelidate';
import 'vue2-datepicker/index.css';

import { client } from '@/domainConfig'


export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orderCartId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      uploadPhotos: [],
      client: client.clientName,
      form: {
        state: null,
        notes: null,
      },
      receivedMaterialVariants: {
        materialVariantId: null,
        totalPrice: null,
      },
      states: [
        { value: 'Cancelled', text: this.$t('Cancelled') },
        { value: 'Received', text: this.$t('Received') },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getSuppliers',
      'getOrderItemsCartId',
    ]),
    calculateAllTotal() {
      return this.getOrderItemsCartId.map((orderItem, key) => {
        return {
          totalPrice: this.receivedMaterialVariants[key],
          materialVariantId: orderItem.materialVariantId,
        };
      });
    },
    calculatingTotalPrice() {
      const values = Object.values(this.receivedMaterialVariants);
      return values.reduce((sum, value) => { return sum + Number(value) }, 0);
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    ...mapActions(['addFileMaterialOrder', 'loadOrderItemsByCardId', 'receiveMaterialVariants',
    ]),
    handleModalShown() {
    },
    calculateTotal(ordered, material) {
      return (ordered / material)
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },

    async onSubmit() {
      try {
        this.loading = true;
        await this.$refs.addFile.uploadFile();
        this.addFileMaterialOrder({
          orderCartId: this.orderCartId,
          object: this.uploadPhotos,
        })
        this.$refs.modal.hide()
        setTimeout(() => {
          this.onReset()
        }, 10)
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      } finally {
        this.loading = false; // Disable the button
        const objekti = {
          orderCartId: this.orderCartId,
          notes: this.form.notes,
          receivedMaterialVariants: this.calculateAllTotal,
        }
        const objekti2 = {
          orderCartId: this.orderCartId,
          state: this.form.state,
          notes: this.form.notes,
        }
        this.$emit('changeState', objekti2);
        if (this.form.state != 'Cancelled') {
          this.$emit('totalPrice', objekti);
        }
        this.uploadPhotos = []
      }
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.receivedMaterialVariants = {}
      this.form.notes = ''
      this.form.state = null
      this.uploadPhotos = []
    },
  },
};
</script>

      <style lang="scss" scoped>
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .vacation {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr;
      }
      .form2 {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      .uploadButtons {
        display: flex;
        align-items: flex-end;
      }
      </style>
