<template>
  <div>
    <b-modal
      id="edit-variant-cart"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditCart')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="form">
        <b-form-group
          id="input-group-8"
          :label="$t('Quantity')"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model.number="form.amount"
          />
        </b-form-group>
        <b-form-group
          id="input-group-8"
          :label="$t('OrderPrice')"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model.number="form.orderedPrice"
          />
        </b-form-group>
      </b-form>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;
                  background: white;
                  color: black;
                  border-color: white;
                  font-weight: 400;"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import 'vue2-datepicker/index.css';

import { client } from '@/domainConfig'


export default {
  components: {
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editForm: {
      type: Object,
    },
  },
  data() {
    return {
      client: client.clientName,
      form: {
        amount: this.editForm.amount,
        orderedPrice: this.editForm.orderedPrice,
      },
    };
  },

  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getSuppliers',
    ]),
  },
  watch: {
    editForm: {
      deep: true, // To watch for nested changes in the prop
      handler(newVal) {
        // Update the form data when the editForm prop changes
        this.form.amount = newVal.amount;
        this.form.orderedPrice = newVal.orderedPrice;
      },
    },
  },
  created() {
  },
  methods: {
    ...mapActions([
    ]),
    handleModalShown() {
    },
    async onSubmit() {
      this.$emit('editOrder', this.form);
      this.$refs.modal.hide();
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
    },
  },
};
</script>

      <style lang="scss" scoped>
      form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .vacation {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr;
      }
      .form2 {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
      .flex-form {
        display: flex;
        flex-direction: column;
      }
      .uploadButtons {
        display: flex;
        align-items: flex-end;
      }
      </style>
